import { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import _ from "lodash";
import * as d3 from "d3";
import { Select } from "antd";
import { useResizeDetector } from "react-resize-detector";
import numeral from "numeral";
import socialData from "../../assets/data/social.json";

const socialLabels = {
  distance_soin_sante: "Distance aux soins de santé",
  espace_vert: "Espace vert",
  famille_monoparentale: "Famille monoparentale",
  locataire: "Locataire",
  maladie_chronique: "Maladie chronique",
  menage_1_personne: "Ménage d'une personne",
  niveau_instruction: "Niveau d'instruction",
  population_sensible: "Population sensible",
  statut_economique: "Statut économique",
};

const ScatterPlot = ({ title, data, selected, select, metric, setMetric }) => {
  const ref = useRef();
  const { width: componentWidth, ref: refParent } = useResizeDetector();
  const [chart, setChart] = useState(null);

  const metrics = _(socialData[0].indexes).keys().value();
  useEffect(() => {
    if (socialData && componentWidth) {
      const chart = Plot.plot({
        width: componentWidth,
        height: componentWidth * 0.6,
        marginRight: 30,
        style: {
          fontSize: "10px",
        },
        x: {
          type: "log",
          grid: true,
          label: "Population",
          //tickFormat: d => `${numeral(d).format("a")}`
        },
        y: {
          //type: "log",
          //domain: [0, 100],
          //percent: true,
          tickFormat: (d) => `${d * 10}`,
          label: "Indice de vulnérabilité",
        },
        r: {
          range: [0, 30],
        },
        marks: [
          Plot.ruleY([0]),
          Plot.dot(socialData, {
            x: "population",
            y: (d) => d.indexes[metric],
            r: "population",
            fill: (d) => (selected.includes(d.name) ? "red" : "grey"),
            opacity: (d) => (selected.includes(d.name) ? 0 : 0.6),
            stroke: (d) => "white",
            strokeWidth: (d) => 1,
          }),
          Plot.dot(
            socialData.filter((d) => selected.includes(d.name)),
            {
              x: "population",
              y: (d) => d.indexes[metric],
              r: "population",
              fill: "red",
              stroke: "black",
              strokeWidth: 2,
              opacity: 0.8,
            },
          ),
          Plot.textX(
            [
              { value: 0.05, label: "↓ Vulnérabilité faible" },
              { value: 0.95, label: "↑ Vulnérabilité haute" },
            ],
            {
              x: 1000,
              y: "value",
              text: (d) => d.label,
              textAnchor: "start",
              dx: 10,
              fontWeight: "bold",
              //stroke: "white",
            },
          ),
          Plot.tip(
            socialData,
            Plot.pointer({
              x: "population",
              y: (d) => d.indexes[metric],
              r: "population",
              title: (d) =>
                `${d.name} \nPopulation totale: ${numeral(d.population).format("0,0.")}\n${socialLabels[metric]} ${numeral(
                  d.indexes[metric] * 10,
                ).format("0.[0]")}`,
            }),
          ),
        ],
      });

      d3.select(chart)
        .selectAll("circle")
        .attr("cursor", "pointer")
        .on("click", (e, d) => {
          //console.log("click", e, d, data[d].municipality);
          select(socialData[d].name);
        });

      ref.current.append(chart);
      setChart(chart);

      return () => chart.remove();
    }
  }, [socialData, selected, metric, componentWidth]);

  return (
    <div ref={refParent}>
      {socialData && (
        <>
          <h2>Social Indexes</h2>
          <div>
            <Select style={{ width: "100%", marginBottom: 8 }} onChange={(value) => setMetric(value)} value={metric}>
              {metrics.map((m) => (
                <Select.Option key={m} value={m}>
                  {socialLabels[m]}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div ref={ref}></div>
        </>
      )}
    </div>
  );
};

export default ScatterPlot;

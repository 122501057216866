import { useState, useEffect, useRef } from "react";
import ImpactGraph from "./ImpactGraph.js";

export default function ImpactGraphComponent(param) {
  const chartEl = useRef(null);
  const [chart, setChart] = useState(null);
  const [props, setProps] = useState(null);
  const [tooltip, setTooltip] = useState(null);

  useEffect(() => {
    createChart();
    window.addEventListener("resize", () => createChart());
    // returned function will be called on component unmount
    return () => {
      //chart.destroy();
      window.removeEventListener("resize", () => createChart());
    };
  }, []);

  useEffect(() => {
    if (chart) {
      chart.update({ props, ...param });
    }
  }, [chart, props, param]);

  const createChart = () => {
    const margin = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };

    if (chartEl && chartEl.current) {
      const elWidth = chartEl.current.clientWidth;
      const elHeight = elWidth;

      const props = {
        ...param,
        margin: margin,
        width: elWidth - margin.left - margin.right,
        height: elHeight - margin.top - margin.bottom,
        setTooltip,
      };

      // Initialise the chart, then render it without transitions.
      const newChart = new ImpactGraph(chartEl.current, props);
      setProps(props);
      setChart(newChart);
      newChart.create(props);
      newChart.update(props);
      newChart.preventTransitions();
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "relative" }} ref={chartEl}></div>
      {tooltip && (
        <div class="tooltip" style={{ left: tooltip.x + "px", top: tooltip.y - 30 + "px", zIndex: 1000, color: "#3d4d4d" }}>
          <div class="node-type" style={{ color: tooltip.data.color }}>
            {tooltip.data?.nodeType}
          </div>
          <div class="title">{tooltip.data?.label}</div>
        </div>
      )}
    </div>
  );
}

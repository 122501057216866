//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";
import ClimatoGridMap from "../../../components/Charts/ClimatoGridMap";

export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Scénarios</h4>
      <div>
        Ces projections sont basées sur le modèle régional MAR forcé par six modèles globaux de la base de données CMIP6 (MIROC6, NOR-ESM2-MM,
        MPI-ESM1-2-HR, EC-EARTH3-VEG, CMCC-CM2-SR5 et IPSL-CM6A-LR) utilisant deux scénarios SSP (SSP370 et SSP585) pour réaliser des projections
        climatiques. L’utilisation du modèle régional MAR est justifiée par le fait qu’il s’agit des seules projections régionales disponibles à
        l’heure actuelle sur les données globales les plus récentes. Le choix des 6 modèles globaux est basé sur les recommandations de{" "}
        <a href="https://www.euro-cordex.net/">CORDEX-Europe</a>. Le GIEC estime les augmentations de temperature au cours du XXIème siècle en
        fonction de scénarios appelés Shared Socio-economic Pathway (SSP), soit « scénarios socio-économiques partagés ». Voir{" "}
        <a href="https://www.sciencedirect.com/science/article/pii/S0959378016300681">(Riahi et al. 2017)</a> pour une description. Le choix des
        scénarios SSP370 et SSP585 pour ce portail s'est basé sur le{" "}
        <a href="https://www.ipcc.ch/report/ar6/syr/downloads/report/IPCC_AR6_SYR_SPM.pdf">6ème rapport du GIEC</a>. Ce rapport indique que la mise en
        oeuvre des politiques climatiques jusqu'en 2020 nous mène sur une trajectoire d’un réchauffement global à la fin du siècle par rapport à la
        période 1850-1900 autour de +3,2°C, soit à mi-chemin entre les scénarios SSP245 et SSP370. Par principe de précaution le scénario SSP370 a
        donc été pris. Le scénario SSP585 a été ajoutée afin de pouvoir explorer les perspectives extrêmes d'un monde à +4°C au niveau planétaire.
      </div>

      <h4>Ce que montrent les cartes</h4>
      <p>
        Sur ces cartes vous pouvez explorer la modification de différents paramètres climatiques en Wallonie. Les scénarios futurs de changements
        climatiques peuvent être sélectionnés en haut de la carte. La partie gauche de la carte montre les statistiques climatiques pour la période de
        référence (1980 à 2010). La partie droite représente son augmentation ou sa diminution selon le scénario climatique choisi pour le futur. Les
        indicateurs sont :
      </p>
      <p>
        <b>Indicateurs relatifs à la température</b>
        <ul>
          <li>
            <b>Tmoy</b>, Température moyenne journalière.{" "}
          </li>
          <li>
            <b>Tmax</b>, Température minimale journalière moyenne.{" "}
          </li>
          <li>
            <b>Tmin</b>, Température maximale journalière moyenne.{" "}
          </li>
          <li>
            <b>J-ete</b>, Nombre de jours d'été, c'est à dire avec une température maximale strictement supérieure à 25°C{" "}
          </li>
          <li>
            <b>J-gel</b>, Nombre de jours de gel, c'est à dire avec une température minimale inférieure (ou égale) à 0°C
          </li>
          <li>
            <b>PCV</b>, Période de Croissance Végétatitive. Il s'agit du nombre de jours séparant les 6 premiers jours consécutifs avec une
            température moyenne strictement supérieure à 5°C des 6 premiers jours consécutifs avec une température moyenne strictement inférieure à
            5°C après le premier juillet{" "}
          </li>
          <li>
            <b>Deg-jour</b>, Degrés-jours. Somme sur tous les jours avec une température moyenne inférieure à 17°C de la différence 17–Tmoy.{" "}
          </li>
          <li>
            <b>J-Canicule</b>, Nombre de jours de vigilance canicule. C'est à dire quand la moyenne glissante sur 3 jours des températures maximales
            est supérieure ou égale à 34°C et une moyenne glissante sur 3 jours des températures minimales supérieure ou égale à 19°C
          </li>
          <li>
            <b>Vague chaleur 5</b>, nombre de périodes d’au-moins 5 jours consécutifs avec une température maximale supérieure ou égale à 25°C et
            comprenant au moins 3 jours avec une température maximale supérieure ou égale à 30°C
          </li>
        </ul>
      </p>
      <p>
        <b>Indicateurs relatifs aux précipitations</b>

        <ul>
          <li>
            <b>Somme precip</b>, Somme des précipitations sur l’année ou la saison considérée{" "}
          </li>
          <li>
            <b>Max j secs</b>, Nombre maximal de jours où les précipitations journalières sont strictement inférieures à 1.5 mm
          </li>
          <li>
            <b>Max cumul 5j</b>, Maximum des cumuls des précipitations sur 5 jours consécutifs{" "}
          </li>
          <li>
            <b>Jours precip</b>, Nombre de jours avec des précipitations journalières supérieures ou égales à 1.5 mm{" "}
          </li>
          <li>
            <b>Jours fortes precip</b>, Nombre de jours avec des précipitations journalières supérieures ou égales à 10 mm{" "}
          </li>
          <li>
            <b>Jours très fortes précip</b>, Nombre de jours avec des précipitations journalières supérieures ou égales à 20 mm
          </li>
          <li>
            <b>Cumul max</b>, Maximum des précipitations journalières{" "}
          </li>
        </ul>
      </p>

      <p>
        <b>Autres indicateurs</b>

        <ul>
          <li>
            <b>Vitesse du vent à 10m</b>, Moyenne de la vitesse du vent sur la période considérée
          </li>
          <li>
            <b>Ecart-type de la vitesse du vent à 10m</b>,{" "}
          </li>
          <li>
            <b>Evapotranspiration de référence</b>, Moyenne journalière sur la période considérée de l’évapotranspiration de référence calculée selon
            la formule de Penman–Monteith
          </li>
          <li>
            <b>Eclairement solaire à la surface</b>, Somme sur la période considérée de l’éclairement solaire à la surface. Fonction de la couverture
            nuageuse, du jour de l’année ainsi que de la concentration en vapeur d’eau et en aérosols dans l’atmosphère
          </li>
          <li>
            <b>Humidité spécifique à 2m</b>, Moyenne journalière de l’humidité spécifique sur la période considérée
          </li>
        </ul>
      </p>
      <h4>Cartes</h4>
      <ClimatoGridMap location={location} />
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Fettweiss, X. et al. 2024]</a>
      </p>
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import BubbleChart from "./BubbleChart.js";

function BubbleChartComponent({ data, width, height, boolean }) {
  const [chart, setChart] = useState(null);
  const ref = useRef();

  function createChart(div) {
    if (chart) {
      chart.destroy();
    }

    const margin = {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
    };

    const elWidth = width || div.offsetParent.offsetWidth;
    const elHeight = height || elWidth;

    const props = {
      margin: margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
    };

    // Initialise the chart, then render it without transitions.
    const _chart = new BubbleChart(ref.current, props);
    _chart.create();
    _chart.update({ data });
    _chart.preventTransitions();
    setChart(_chart);
  }

  useEffect(() => {
    // First render of the D3 chart.
    createChart(ref.current);
    return () => {
      // Tear down the chart and remove the listener.
      if (chart) chart.destroy();
    };
  }, [data]);

  return <div ref={ref}></div>;
}

export default BubbleChartComponent;

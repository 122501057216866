import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Leaves from './Leaves';

class LeavesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
      width: 0,
      height: 0
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    const currentState = this.getChartState(nextProps);
    if (nextProps.data && chart) {
      chart.update(currentState);
    }
  }

  getChartState = (props) => {
    return {
      data: props.data,
      selection: props.selection,
      onSelect: props.onSelect,
      contextAverage: props.contextAverage
    };
  }

  setTooltip = (tooltip) => {
    this.setState({ tooltip })
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state
    if (chart) {
      chart.destroy()
    }

    const margin = {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
    };

    const elWidth = el.offsetWidth || 400;
    const elHeight = elWidth;
    const props = {
      margin,
      width: (elWidth - margin.left) - margin.right,
      height: (elHeight - margin.top) - margin.bottom,
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new Leaves(el, props),
      width: elWidth,
      height: elHeight,
    }, function callback() {
      this.state.chart.create();
      this.state.chart.update(this.getChartState(this.props));
      this.state.chart.preventTransitions();
    });
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div ref='chart'/>
      </div>
    );
  }
}

export default LeavesComponent

import { useEffect, useRef, useState } from "react";
import { Divider, Radio, Row, Col } from "antd";
import inactionCostMetrics from "../../../assets/data/inactionCostMetrics.json";
import inactionCosts from "../../../assets/data/inactionCosts.json";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import _ from "lodash";
import { useResizeDetector } from "react-resize-detector";
import numeral from "numeral";
import { useLocation } from "../../../components/Thematic/Utils/Location";
import BubbleChart from "../../Charts/BubbleChart/BubbleChartComponent";

function InactionCost({ theme }) {
  const ref = useRef();
  const { width: componentWidth, ref: refParent } = useResizeDetector();
  const [chart, setChart] = useState(null);
  const [scenarioType, setScenarioType] = useState("optimiste");
  const [location] = useLocation();
  const [stats, setStats] = useState(null);

  const metrics = _(inactionCostMetrics).keyBy("metricId").value();

  useEffect(() => {
    const data = _(inactionCosts)
      .filter((d) => d.commune !== "Wallonie")
      .flatMap((d) =>
        _(inactionCostMetrics)
          .filter((d) => !d.scenarioType || d.scenarioType === scenarioType)
          .map((m) => ({
            metricId: m["metricId"],
            metric: `${m["category"]} | ${m["impactLabel"]}`,
            scenarioType: m["scenarioType"],
            value: d[m.metricId] * 1000,
            category: m["category"],
            commune: d.commune,
            communeCode: d.code,
          }))
          .value(),
      )
      .filter((d) => d.value !== null && d.value > 0)
      .value();

    const categories = _(data).map("category").uniq().value();
    const color = d3.scaleOrdinal().domain(categories).range(d3.schemeObservable10);

    const top = location
      ? _(data)
          .filter((d) => d.commune?.toUpperCase() === location?.toUpperCase())
          .groupBy("scenarioType")
          .mapValues((g) => {
            return g.map((d) => ({
              value: d.metric,
              count: d.value,
              color: color(d.category),
            }));
          })
          .value()
      : null;

    const s = top
      ? {
          //@ts-ignore --
          top: top[scenarioType]?.concat(top["null"] || []),
          counts: {
            //@ts-ignore --
            totalCount: _(top)
              .map((g) =>
                _(g)
                  .concat(top["null"] || [])
                  .map("count")
                  .sum(),
              )
              .max(),
          },
        }
      : null;
    //@ts-ignore --
    setStats(s);

    const chart = Plot.plot({
      marginLeft: 270,
      x: {
        label: "Coûts →",
        type: "log",
        axis: "top",
      },
      marks: [
        Plot.ruleX([0]),
        //@ts-ignore --
        Plot.tickX(data, {
          x: "value",
          y: "metricId",
          strokeOpacity: 0.3,
          tip: location ? false : true,
          title: (d) => `${d.commune} - ${numeral(d.value).format("0,0.")}€`,
        }),
        Plot.gridX(),
        Plot.tickX(data, Plot.groupY({ x: "median" }, { x: "value", y: "metricId", stroke: "red", strokeWidth: 0, sort: { y: "x", reverse: true } })),
        Plot.tickX(
          data.filter((d) => d.commune?.toUpperCase() === location?.toUpperCase() && !d.communeCode?.toString().endsWith("000")),
          {
            x: "value",
            y: "metricId",
            stroke: "red",
            strokeWidth: 4,
            tip: true,
            title: (d) => `${d.commune} - ${numeral(d.value).format("0,0.")}€`,
          },
        ),
        Plot.axisY({
          color: (d) => color(metrics[d].category),
          fontWeight: "bold",
          tickFormat: (d) => `${metrics[d].category} | ${metrics[d].impactLabel}`,
          label: null,
          tickSize: 0,
        }),
      ],
    });

    //@ts-ignore --
    ref.current.append(chart);
    //@ts-ignore --
    setChart(chart);

    return () => chart.remove();
  }, [theme, location, scenarioType]);

  return (
    <div>
      <Divider orientation="left">Coût de l'inaction</Divider>
      <Row justify="end" align="middle" style={{ marginBottom: 8 }}>
        <div style={{ marginRight: 8 }}>Scénarios:</div>
        <Radio.Group
          size="small"
          options={[
            { label: "Optimistes", value: "optimiste" },
            { label: "Modérés", value: "modéré" },
            { label: "Extrèmes", value: "extrème" },
          ]}
          value={scenarioType}
          optionType="button"
          buttonStyle="solid"
          onChange={(e) => setScenarioType(e.target.value)}
        />
      </Row>
      <Row>
        <Col span={14} offset={5}>
          {stats && <BubbleChart data={stats} />}
        </Col>
      </Row>
      <div ref={refParent}>
        <>
          {/* @ts-ignore -- */}
          <div ref={ref}></div>
        </>
      </div>
    </div>
  );
}

export default InactionCost;

import Chart from "../chart";
import * as d3 from "d3";
import { s3Colors } from "../../Constants";
import "./ImpactGraph.css";
import _ from "lodash";

const mainLabelRange = [0.9, 0.99];
const subLabelRange = [0.85, 0.89];
const disCenterPos = 0.85;
const maxProjectRange = 0.81;
const suffix = "ImpactGraph";

const colors = ["#003f5c", "#58508d", "#8a508f", "#bc5090", "#de5a79", "#ff6361", "#ff8531", "#ffa600"];

export default class ImpactGraph extends Chart {
  create() {
    this.svg = super.createRoot();

    d3.select(this.el).selectAll("*").remove();
    //d3.select(this.el.parentNode).style("width", this.props.width).style("height", this.props.width);
    //d3.select(this.el).selectAll("svg").style("z-index", 1).style("position", "absolute").style("top", 0).style("left", 0);

    this.div = d3.select(this.el).style("width", `${this.props.width}px`).style("height", `${this.props.width}px`);
    this.canvas = this.div.append("canvas");
    this.svg = this.div.append("svg").attr("width", this.props.width).attr("height", this.props.width);
    this.svg
      .style("position", "absolute")
      .style("top", 0)
      .style("left", 0)
      .style("z-index", 1)
      .style("font-family", "apple-system")
      .style("z-index", 200);

    // Fix aliasing issue
    // 1. Multiply the canvas's width and height by the devicePixelRatio
    const ratio = window.devicePixelRatio || 1;
    this.canvas.node().width = this.props.width * ratio;
    this.canvas.node().height = this.props.height * ratio;
    // 2. Force it to display at the original (logical) size with CSS or style attributes
    this.canvas.node().style.width = this.props.width + "px";
    this.canvas.node().style.height = this.props.height + "px";
    this.canvas.style("position", "absolute").style("top", 0).style("left", 0).style("z-index", 1);

    this.ctx = this.canvas.node().getContext("2d");

    this.ctx.clearRect(-this.canvas.node().width, -this.canvas.node().height, this.canvas.node().width, this.canvas.node().height);

    this.ctx.scale(ratio, ratio);
    this.ctx.translate(this.props.width / 2, this.props.height / 2);

    this.svg.style("font-family", "roboto");

    // this.svg
    //   .append("rect")
    //   .attr("width", this.props.width)
    //   .attr("height", this.props.width)
    //   .attr("fill", "white")
    //   .style("opacity", 0)
    //   .on("click", () => {
    //     this.selection = null;
    //     //self.select(null, "dis");
    //   });

    this.legend = this.svg.append("g");
    this.main = this.svg.append("g").attr("transform", `translate(${this.props.width / 2},${this.props.width / 2})`);

    this.mainDis = this.main.append("g");
    this.subDis = this.main.append("g");
    this.linkLayer = this.main.append("g").attr("class", "links");
    this.projectDots = this.main.append("g");
    this.centerSelection = this.main.append("g");

    this.highlightHover = this.main
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 10)
      .attr("fill", "none")
      .attr("stroke", s3Colors.keppel) //s3Colors.redWallonia)
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", 1)
      .style("display", "none");

    this.highlightSelection = this.main
      .append("circle")
      //.attr('class', 'rotating-animation')
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 10)
      .attr("fill", "none")
      .attr("stroke", s3Colors.keppel)
      .attr("stroke-width", 4)
      .attr("opacity", 0.7)
      //.attr('stroke-dasharray', 1)
      .style("display", "none");

    const self = this;
    this.eventCatcher = this.main
      .append("rect")
      .attr("class", "eventCatcher")
      .attr("x", -this.props.width / 2)
      .attr("y", -this.props.width / 2)
      .attr("width", this.props.width)
      .attr("height", this.props.width)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mousemove", function (event) {
        self.mouseMoveChart(event, self);
      })
      .on("mouseleave", function () {
        self.props.setTooltip(null);
        self.highlightHover.style("display", "none");
      });
    // .on('click', function(event) { mouseClickChart(event, self) })

    const themes = this.props.themes;
    const clusters = _(themes)
      .uniqBy("cluster")
      .map((d, i) => ({ name: d.clusterLabel, color: colors[i % colors.length], size: 1, id: d.cluster }));

    const impacts = _(themes)
      .map((d) => ({
        id: d.id,
        label: d.label,
        clusterId: d.cluster,
        clusterLabel: d.clusterLabel,
      }))
      .uniqBy("id")
      .value();

    const aleas = _(themes)
      .flatMap("aleas")
      .filter()
      .groupBy("name")
      .map((g) => ({
        id: g[0].name,
        label: g[0].name,
        size: g.length,
        color: "#f4f1de",
      }))
      .value();

    const facteursAggravant = _(themes)
      .flatMap("facteurs_aggravant")
      .filter()
      .groupBy("name")
      .map((g) => ({
        id: g[0].name,
        label: g[0].name,
        size: g.length,
        color: "#e07a5f",
      }))
      .value();

    const expositions = _(themes)
      .flatMap("expositions")
      .filter()
      .groupBy("name")
      .map((g) => ({
        id: g[0].name,
        label: g[0].name,
        size: g.length,
        color: "#3d405b",
      }))
      .value();

    const vulnérabilités = _(themes)
      .flatMap("vulnérabilités")
      .filter()
      .groupBy("name")
      .map((g) => ({
        id: g[0].name,
        label: g[0].name,
        size: g.length,
        color: "#81b29a",
      }))
      .value();

    const detailedImpacts = _(themes)
      .flatMap("impacts")
      .filter()
      .groupBy("name")
      .map((g) => ({
        id: g[0].name,
        label: g[0].name,
        size: g.length,
        color: "#f2cc8f",
      }))
      .value();

    this.data = {
      themes,
      clusters,
      impacts,
      aleas,
      facteursAggravant,
      expositions,
      vulnérabilités,
      detailedImpacts,
    };
    this.drawClusters();
    this.drawSubLabels();
    this.computeLayout();
    this.drawLinks();
    this.drawNodes();
  }

  drawNodes = () => {
    // this.layout.impacts.forEach((d) => {
    //   this.ctx.globalAlpha = 1;
    //   this.ctx.beginPath();
    //   this.ctx.arc(d.x, d.y, d.r, 0, 2 * Math.PI);
    //   this.ctx.fillStyle = "red";
    //   this.ctx.fill();
    //   this.ctx.strokeStyle = "white";
    //   this.ctx.lineWidth = 1;
    //   this.ctx.stroke();
    // });

    this.layout.aleas.forEach((d) => {
      this.ctx.globalAlpha = 1;
      this.ctx.beginPath();
      this.ctx.arc(d.x, d.y, d.r, 0, 2 * Math.PI);
      this.ctx.fillStyle = d.color;
      this.ctx.fill();
      this.ctx.strokeStyle = "white";
      this.ctx.lineWidth = 1;
      this.ctx.stroke();
    });

    this.layout.facteursAggravants.forEach((d) => {
      this.ctx.globalAlpha = 1;
      this.ctx.beginPath();
      this.ctx.arc(d.x, d.y, d.r, 0, 2 * Math.PI);
      this.ctx.fillStyle = d.color;
      this.ctx.fill();
      this.ctx.strokeStyle = "white";
      this.ctx.lineWidth = 1;
      this.ctx.stroke();
    });

    this.layout.expositions.forEach((d) => {
      this.ctx.globalAlpha = 1;
      this.ctx.beginPath();
      this.ctx.arc(d.x, d.y, d.r, 0, 2 * Math.PI);
      this.ctx.fillStyle = d.color;
      this.ctx.fill();
      this.ctx.strokeStyle = "white";
      this.ctx.lineWidth = 1;
      this.ctx.stroke();
    });

    this.layout.vulnérabilités.forEach((d) => {
      this.ctx.globalAlpha = 1;
      this.ctx.beginPath();
      this.ctx.arc(d.x, d.y, d.r, 0, 2 * Math.PI);
      this.ctx.fillStyle = d.color;
      this.ctx.fill();
      this.ctx.strokeStyle = "white";
      this.ctx.lineWidth = 1;
      this.ctx.stroke();
    });

    this.layout.detailedImpacts.forEach((d) => {
      this.ctx.globalAlpha = 1;
      this.ctx.beginPath();
      this.ctx.arc(d.x, d.y, d.r, 0, 2 * Math.PI);
      this.ctx.fillStyle = "grey";
      this.ctx.fill();
      this.ctx.strokeStyle = "white";
      this.ctx.lineWidth = 1;
      this.ctx.stroke();
    });
  };

  drawLinks() {
    this.ctx.globalAlpha = 0.5;
    this.layout.links.forEach((d) => {
      this.ctx.beginPath();
      this.drawArcTo(this.ctx, d.source, d.target);
      this.ctx.strokeStyle = "grey";
      this.ctx.lineWidth = this.props.width * 0.001;
      this.ctx.stroke();
    });

    this.ctx.globalAlpha = 1;
  }

  drawArcTo(ctx, source, target) {
    const r = Math.sqrt((target.x - source.x) ** 2 + (target.y - source.y) ** 2) * 2;
    //Find center of the arc function
    const centers = this.findCenters(r, source, target);
    const sign = 0; // Math.random() > 0.5
    const c = sign ? centers.c2 : centers.c1;
    const ang1 = Math.atan2(source.y - c.y, source.x - c.x);
    const ang2 = Math.atan2(target.y - c.y, target.x - c.x);
    ctx.arc(c.x, c.y, r, ang1, ang2, sign);
  }

  findCenters(r, p1, p2) {
    // pm is middle point of (p1, p2)
    const pm = { x: 0.5 * (p1.x + p2.x), y: 0.5 * (p1.y + p2.y) };
    // compute leading vector of the perpendicular to p1 p2 == C1C2 line
    let perpABdx = -(p2.y - p1.y);
    let perpABdy = p2.x - p1.x;
    // normalize vector
    const norm = Math.sqrt(perpABdx ** 2 + perpABdy ** 2);
    perpABdx /= norm;
    perpABdy /= norm;
    // compute distance from pm to p1
    const dpmp1 = Math.sqrt((pm.x - p1.x) ** 2 + (pm.y - p1.y) ** 2);
    // sin of the angle between { circle center,  middle , p1 }
    const sin = dpmp1 / r;
    // is such a circle possible ?
    if (sin < -1 || sin > 1) {
      return null; // no, return null
    }
    // yes, compute the two centers
    const cos = Math.sqrt(1 - sin ** 2); // build cos out of sin
    const d = r * cos;
    const res1 = { x: pm.x + perpABdx * d, y: pm.y + perpABdy * d };
    const res2 = { x: pm.x - perpABdx * d, y: pm.y - perpABdy * d };

    return { c1: res1, c2: res2 };
  }

  drawClusters() {
    const self = this;
    const radius = this.props.width / 2;

    const curvedArc = d3
      .arc()
      .innerRadius(radius * mainLabelRange[0])
      .outerRadius(radius * mainLabelRange[1] - 1)
      .cornerRadius(radius * (mainLabelRange[1] - mainLabelRange[0]));

    const straightArc = d3
      .arc()
      .innerRadius(radius * mainLabelRange[0])
      .outerRadius(radius * mainLabelRange[1] - 1);

    const arcs = d3
      .pie()
      .startAngle((-90 * Math.PI) / 180)
      .endAngle((-90 * Math.PI) / 180 + 2 * Math.PI)
      .padAngle(0.01)
      .sort(null)
      .value((d) => d.size)(this.data.clusters);

    this.mainDis
      .selectAll(".mainLabelArcBackGround")
      .data(arcs)
      .enter()
      .append("path")
      .attr("class", "mainLabelArcBackGround")
      .attr("fill", (d) => d.data.color)
      .attr("d", curvedArc)
      .style("cursor", "pointer")
      .style("cursor", "pointer");
    // .on('click', function(e, d) {
    //   console.log('click', d)
    //   selection = {
    //     type: 'dis',
    //     data: d
    //   }
    //   updateCenter()
    // })

    this.mainDis
      .selectAll(".mainLabelArcPosition")
      .data(arcs)
      .enter()
      .append("path")
      .attr("class", "mainLabelArcPosition")
      .attr("fill", (d) => "none")
      .attr("d", straightArc)
      .each(function (d, i) {
        // Search pattern for everything between the start and the first capital L
        const firstArcSection = /(^.+?)L/;
        // Grab everything up to the first Line statement
        let newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
        // Replace all the comma's so that IE can handle it
        newArc = newArc.replace(/,/g, " ");

        // If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
        // flip the end and start position
        if (newArc !== "" && d.endAngle > (90 * Math.PI) / 180) {
          const startLoc = /M(.*?)A/; // Everything between the first capital M and first capital A
          const middleLoc = /A(.*?)0 [0,1] 1/; // Everything between the first capital A and 0 0 1
          // Everything between the first 0 0 1 and the end of the string (denoted by $)
          const endLoc = /0 [0,1] 1 (.*?)$/;
          // Flip the direction of the arc by switching the start en end point (and sweep flag)
          // of those elements that are below the horizontal line

          // if(endLoc.exec( newArc ) && startLoc.exec( newArc ) && middleLoc.exec( newArc )) {
          const newStart = endLoc.exec(newArc)[1];
          const newEnd = startLoc.exec(newArc)[1];
          const middleSec = middleLoc.exec(newArc)[1];

          // Build up the new arc notation, set the sweep-flag to 0
          newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
        }
        // Create a new invisible arc that the text can flow along
        self.mainDis.append("path").attr("class", "hiddenDonutArcs").attr("id", `${suffix}_donutArc${i}`).attr("d", newArc).style("fill", "none");
      })
      .style("cursor", "pointer");
    // .on('click', function(e, d) {
    //   console.log('click', d)
    //   selection = {
    //     type: 'dis',
    //     data: d
    //   }
    //   updateCenter()
    // })

    const fontSize = this.props.width * 0.02;
    this.mainDis
      .selectAll(".mainLabelText")
      .data(arcs)
      .enter()
      .append("text")
      .attr("class", "mainLabelText")
      // Move the labels below the arcs for those slices with an end angle greater than 90 degrees
      .attr("dy", function (d, i) {
        return d.endAngle <= (90 * Math.PI) / 180 ? fontSize * 1.5 : -fontSize * 0.75;
      })
      .append("textPath")
      .attr("startOffset", "50%")
      .style("text-anchor", "middle")
      .style("font-weight", 600)
      .style("font-size", fontSize)
      .style("text-transform", "uppercase")
      .style("fill", "white")
      .attr("xlink:href", (d, i) => `#${suffix}_donutArc${i}`)
      .style("cursor", "pointer")
      .text((d) => d.data.name);
    // .on('click', function(e, d) {
    //   selection = {
    //     type: 'dis',
    //     data: d
    //   }
    //   updateCenter()
    // })

    return arcs;
  }

  drawSubLabels() {
    const self = this;
    const subLabels = _(this.data.impacts)
      .groupBy((d) => `${d.clusterId}`)
      .flatMap((g) =>
        g.map((d) => ({
          ...d,
          id: d.id,
          catLabel: d.disLabel,
          subCatLabel: d.label,
          size: 1 / g.length,
          color: this.data.clusters.find((c) => c.id === d.clusterId).color,
        })),
      )
      .orderBy("number")
      .value();
    const radius = this.props.width / 2;

    const curvedArc = d3
      .arc()
      .innerRadius(radius * subLabelRange[0])
      .outerRadius(radius * subLabelRange[1] - 1)
      .cornerRadius(radius * (subLabelRange[1] - subLabelRange[0]));

    const straightArc = d3
      .arc()
      .innerRadius(radius * subLabelRange[0])
      .outerRadius(radius * subLabelRange[1] - 1);

    const arcs = d3
      .pie()
      .startAngle((-90 * Math.PI) / 180)
      .endAngle((-90 * Math.PI) / 180 + 2 * Math.PI)
      .padAngle(0.01)
      .sort(null)
      .value((d) => d.size)(subLabels);

    this.disCenters = arcs.map((d) => ({
      ...d.data,
      angle: (d.startAngle + d.endAngle) / 2,
      type: "subDis",
    }));

    this.subDis
      .selectAll(".subLabelArcBackGround")
      .data(arcs)
      .enter()
      .append("path")
      .attr("class", "subLabelArcBackGround")
      .attr("fill", (d) => d.data.color || "lightgrey")
      .attr("d", curvedArc)
      .style("cursor", "pointer");
    // .on('click', function(e, d) {
    //   self.select(d.data.id, 'dis')
    // })

    this.subDis
      .selectAll(".subLabelArcPosition")
      .data(arcs)
      .enter()
      .append("path")
      .attr("class", "subLabelArcPosition")
      .attr("fill", (d) => "none")
      .attr("d", straightArc)
      .each(function (d, i) {
        // Search pattern for everything between the start and the first capital L
        var firstArcSection = /(^.+?)L/;
        // Grab everything up to the first Line statement
        var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
        // Replace all the comma's so that IE can handle it
        newArc = newArc.replace(/,/g, " ");

        // If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
        // flip the end and start position
        if (newArc !== "" && d.endAngle > (90 * Math.PI) / 180) {
          const startLoc = /M(.*?)A/; // Everything between the first capital M and first capital A
          const middleLoc = /A(.*?)0 [0,1] 1/; // Everything between the first capital A and 0 0 1
          // Everything between the first 0 0 1 and the end of the string (denoted by $)
          const endLoc = /0 [0,1] 1 (.*?)$/;
          // Flip the direction of the arc by switching the start en end point (and sweep flag)
          // of those elements that are below the horizontal line

          var newStart = endLoc.exec(newArc)[1];
          var newEnd = startLoc.exec(newArc)[1];
          var middleSec = middleLoc.exec(newArc)[1];
          newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
        }

        //Create a new invisible arc that the text can flow along
        self.subDis
          .append("path")
          .attr("class", "hiddenDonutArcs")
          .attr("id", `${suffix}_donutArc${i}_${d.data.catLabel}_${d.data.subCatLabel}`)
          .attr("d", newArc)
          .style("fill", "none");
      });
    // .on("click", function (e, d) {
    //   self.select(d.data.id, "dis");
    // });

    const fontSize = this.props.width * 0.009;
    this.subDis
      .selectAll(".subLabelText")
      .data(arcs)
      .enter()
      .append("text")
      .attr("class", "subLabelText")
      //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
      .attr("dy", function (d, i) {
        return d.endAngle <= (90 * Math.PI) / 180 ? fontSize * 1.4 : -fontSize * 0.7;
      })
      .append("textPath")
      .attr("startOffset", "50%")
      .style("text-anchor", "middle")
      .style("font-size", fontSize)
      .style("font-weight", "bold")
      .style("text-transform", "uppercase")
      .style("fill", "white")
      .attr("xlink:href", (d, i) => `#${suffix}_donutArc${i}_${d.data.catLabel}_${d.data.subCatLabel}`)
      .text((d) => d.data.subCatLabel)
      .style("cursor", "pointer");
    // .on("click", function (e, d) {
    //   self.select(d.data.id, "dis");
    // });

    return arcs;
  }

  applySimulation(nodes, links) {
    const simulation = d3
      .forceSimulation(nodes)
      .force("charge", d3.forceManyBody().strength(-5))
      .force(
        "link",
        d3
          .forceLink()
          .id((d) => d.id)
          .links(links),
      )
      .force(
        "cx",
        d3
          .forceX()
          .x((d) => 0)
          .strength(0.02),
      )
      .force(
        "cy",
        d3
          .forceY()
          .y((d) => 0)
          .strength(0.02),
      )
      .force(
        "collide",
        d3
          .forceCollide()
          .radius((d) => (d.r || 10) + 2)
          .strength(1),
      )
      .stop();

    let i = 0;
    while (simulation.alpha() > 0.01 && i < 200) {
      simulation.tick();
      i++;
      console.log(`${Math.round((100 * i) / 200)}%`);

      const radius = (maxProjectRange * this.props.width) / 2;
      nodes.forEach((d) => {
        const dist = Math.sqrt(d.x ** 2 + d.y ** 2);
        if (!d.fx && !d.fy && dist > radius) {
          const angle = Math.atan2(d.x, d.y);
          const x = (radius - 10) * Math.sin(angle);
          const y = (radius - 10) * Math.cos(angle);
          d.x = x;
          d.y = y;
        }
      });
    }

    return simulation.nodes();
  }

  computeLayout() {
    //const radius = this.props.width / Math.sqrt(projects.length) / 10;

    const impactCenters = this.disCenters.map((d) => {
      const r = this.props.width * (disCenterPos / 2) + 5;
      const a = d.angle - Math.PI / 2;
      const x = r * Math.cos(a);
      const y = r * Math.sin(a);
      return { ...d, x, y, angle: a };
    });

    const radiusScale = d3
      .scaleSqrt()
      .range([0, this.props.width * 0.015])
      .domain([0, d3.max(this.data.aleas, (d) => d.size)]);

    this.nodes = [
      ...impactCenters.map((d) => ({
        ...d,
        fx: d.x,
        fy: d.y,
        nodeType: "impact",
        color: "grey",
        r: this.props.width * 0.006,
      })),
      ...this.data.aleas.map((p, i) => ({
        ...p,
        x: 0,
        y: 0,
        nodeType: "aleas",
        color: "#afe0ff",
        r: radiusScale(p.size),
      })),
      ...this.data.facteursAggravant.map((p, i) => ({
        ...p,
        x: 0,
        y: 0,
        nodeType: "facteursAggravant",
        color: "tomato",
        r: radiusScale(p.size),
      })),
      ...this.data.expositions.map((p, i) => ({
        ...p,
        x: 0,
        y: 0,
        nodeType: "exposition",
        color: "#ffce8d",
        r: radiusScale(p.size),
      })),
      ...this.data.vulnérabilités.map((p, i) => ({
        ...p,
        x: 0,
        y: 0,
        nodeType: "vulnérabilité",
        color: "#acec66",
        r: radiusScale(p.size),
      })),
      ...this.data.detailedImpacts.map((p, i) => ({
        ...p,
        x: 0,
        y: 0,
        nodeType: "detailedImpact",
        color: "grey",
        r: 4, //radiusScale(p.size),
      })),
    ];

    const aleasLinks = _(this.data.themes)
      .flatMap((d) =>
        _(d.aleas)
          .uniqBy("name")
          .map((alea) => ({
            source: d.id,
            target: alea.name,
            color: "grey",
          }))
          .value(),
      )
      .value();

    const expositionsLinks = _(this.data.themes)
      .flatMap((d) =>
        _(d.expositions)
          .uniqBy("name")
          .flatMap((exposition) =>
            _(d.aleas)
              .uniqBy("name")
              .map((alea) => ({
                source: exposition.name,
                target: alea.name,
                color: "grey",
              }))
              .value(),
          )
          .value(),
      )
      .value();

    const detailedImpactLinks = _(this.data.themes)
      .flatMap((d) =>
        _(d.impacts)
          .uniqBy("name")
          .flatMap((impact) =>
            _(d.aleas)
              .uniqBy("name")
              .map((alea) => ({
                source: impact.name,
                target: alea.name,
                color: "grey",
              }))
              .value(),
          )
          .value(),
      )
      .value();

    const vulnérabilitésLinks = _(this.data.themes)
      .flatMap((d) =>
        _(d.vulnérabilités)
          .uniqBy("name")
          .flatMap((vulnérabilité) =>
            _(d.aleas)
              .uniqBy("name")
              .map((alea) => ({
                source: vulnérabilité.name,
                target: alea.name,
                color: "grey",
              }))
              .value(),
          )
          .value(),
      )
      .value();

    const facteursAggravantsLinks = _(this.data.themes)
      .flatMap((d) =>
        _(d.facteurs_aggravant)
          .uniqBy("name")
          .flatMap((facteursAggravant) =>
            _(d.expositions)
              .uniqBy("name")
              .map((exposition) => ({
                source: facteursAggravant.name,
                target: exposition.name,
                color: "grey",
              }))
              .value()
              .concat(
                _(d.vulnérabilités)
                  .uniqBy("name")
                  .map((vulnérabilité) => ({
                    source: facteursAggravant.name,
                    target: vulnérabilité.name,
                    color: "grey",
                  }))
                  .value(),
              ),
          )
          .value(),
      )
      .value();

    this.links = detailedImpactLinks.concat(aleasLinks).concat(expositionsLinks).concat(vulnérabilitésLinks).concat(facteursAggravantsLinks);
    this.applySimulation(this.nodes, this.links);

    this.delaunay = d3.Delaunay.from(
      this.nodes,
      (d) => d.x,
      (d) => d.y,
    );

    this.layout = {
      impacts: this.nodes.filter((d) => d.nodeType === "impact"),
      detailedImpacts: this.nodes.filter((d) => d.nodeType === "detailedImpact"),
      aleas: this.nodes.filter((d) => d.nodeType === "aleas"),
      facteursAggravants: this.nodes.filter((d) => d.nodeType === "facteursAggravant"),
      expositions: this.nodes.filter((d) => d.nodeType === "exposition"),
      vulnérabilités: this.nodes.filter((d) => d.nodeType === "vulnérabilité"),
      links: this.links,
      // strategicAreas,
      // iis,
      // partners,
      // aleasLinks,
      // iisLinks,
      // partnerLinks,
    };
  }

  findClosestNode = (x, y) => {
    const closestNodeIndex = this.delaunay.find(x, y);
    const closestNode = this.nodes[closestNodeIndex];
    return closestNode;
  };

  dist = (x1, y1, x2, y2) => Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);

  MAX_DIST = () => Math.max(10, this.props.width * 0.05);

  mouseMoveChart = (event, self) => {
    const bb = self.el.getBoundingClientRect();
    const x = event.clientX - bb.left - this.props.width / 2;
    const y = event.clientY - bb.top - this.props.width / 2;
    const closestNode = this.findClosestNode(x, y);

    if (this.dist(x, y, closestNode.x, closestNode.y) < this.MAX_DIST()) {
      this.highlightHover.style("display", "");
      this.highlightHover.attr("cx", closestNode.x);
      this.highlightHover.attr("cy", closestNode.y);
      this.highlightHover.attr("r", closestNode.r + 2);

      this.props.setTooltip({
        x: event.clientX,
        y: event.clientY,
        data: closestNode,
        target: this.highlightHover.node(),
      });
    } else {
      this.highlightHover.style("display", "none");
      this.props.setTooltip(null);
    }
  };

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    this.drawChart(state);
  }

  drawChart(state) {}
}

//supprimer selon pertinence
import React, { useContext, useEffect, useState } from "react";
import { EventContext, EventHighlight, useFlyTo } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";
import { usePopulation } from "../../../hooks/population";
import Leaves from "../../../components/Charts/Leaves/LeavesComponent";
import ScatterPlotSocial from "../../../components/Charts/ScatterPlotSocial";
import socialData from "../../../assets/data/social.json";
import _ from "lodash";
import * as d3 from "d3";

const socialDataMap = _(socialData).keyBy("name").value();

const socialLabels = {
  distance_soin_sante: "Distance aux soins de santé",
  espace_vert: "Espace vert",
  famille_monoparentale: "Famille monoparentale",
  locataire: "Locataire",
  maladie_chronique: "Maladie chronique",
  menage_1_personne: "Ménage d'une personne",
  niveau_instruction: "Niveau d'instruction",
  population_sensible: "Population sensible",
  statut_economique: "Statut économique",
};

export default function Content() {
  //@ts-ignore
  const flyTo = useFlyTo();
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();
  const population = usePopulation() || [];
  const [communeData, setCommuneData] = useState<any>(null);
  const [metric, setMetric] = useState("statut_economique");

  const avgBase = _(socialData[0].indexes)
    .keys()
    .map((metric) => ({ metric, averageBase: d3.median(socialData, (d) => d.indexes[metric]) }))
    .keyBy("metric")
    .mapValues("averageBase")
    .value();

  useEffect(() => {
    if (location && socialDataMap[location]) {
      setCommuneData(
        _(socialDataMap[location].indexes)
          .map((average, metric) => ({
            id: metric,
            average: average * 10,
            averageBase: avgBase[metric] * 10,
            label: socialLabels[metric],
            color: "red",
          }))
          .orderBy("label")
          .value(),
      );
    } else {
      setCommuneData(
        _(socialData[0].indexes)
          .map((average, metric) => ({
            id: metric,
            average: 0,
            averageBase: avgBase[metric] * 10,
            label: socialLabels[metric],
            color: "red",
          }))
          .orderBy("label")
          .value(),
      );
    }
  }, [location]);

  return (
    <div>
      <ScatterPlotSocial
        title="Social Index"
        data={population}
        selected={[location]}
        select={(location: string) => {
          flyTo(location, "if search, force going to location");
        }}
        metric={metric}
        setMetric={setMetric}
      />
      <h3 style={{ marginTop: 64, color: "grey", textDecoration: "underline" }}>{location || "Sélectionnez une commune"}</h3>
      <div style={{ marginBottom: 64, color: "grey" }}>Indice de vulnérabilité: 0 → Faible | 10 → Élevé</div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ maxWidth: 400, marginBottom: 64 }}>
          <Leaves data={communeData} selection={metric} onSelect={setMetric} />
        </div>
      </div>
      <h4>Ce que montrent les cartes</h4>
      <p>
        La carte représente l'indicateur composite de vulnérabilité. Cet indicateur a été construit en croisant des données disponibles à l'échelle
        des secteurs statistiques et permettant de caractériser plusieurs dimensions de la vulnérabilité socio-économique du territoire. Notamment :
        <ul>
          <li>Données de population et projections de la population en 2050</li>
          <li>Age</li>
          <li>Revenu médian</li>
          <li>Accès aux droits fondamentaux</li>
          <li>Bénéficiaires BIM</li>
        </ul>
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Loozen, Y. et al. 2024]</a>
      </p>
    </div>
  );
}

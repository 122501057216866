import React, { useEffect } from "react";
import { Breadcrumb, Row } from "antd";
import { geojsonFile, geojsonWallonia, geojsonMunicipalities, geojsonProvinces } from "../../../services/geojson";
import { useMap } from "react-map-gl";
import bbox from "@turf/bbox";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import { PANEL_WIDTH, isMobileMode } from "../SidePanel/SidePanel";
import { useSize } from "../../../hooks/size";
import { LngLat, Map } from "mapbox-gl";
import { point, booleanPointInPolygon, Feature, FeatureCollection } from "@turf/turf";
import { useQueryParam, StringParam, JsonParam } from "use-query-params";

export enum LocationScale {
  ROOT = 0, // Global level
  Province = 1,
  Municipality = 2,
}

/**
 *
 * @param location a province or municipality name
 * @returns the scale of the location:
 *      - LocationScale.PROVINCE if the location is a province
 *      - LocationScale.MUNICIPALITY if the location is a municipality
 *      - LocationScale.ROOT if the location is not found
 */
export function extractLocationScale(location: string): LocationScale {
  const location_lower = location?.toLowerCase();
  for (let province of geojsonFile.objects.provinces.geometries) {
    if (location_lower === province.properties.name_fr?.toLowerCase()) return LocationScale.Province;
    if (location_lower === province.properties.name_nl?.toLowerCase()) return LocationScale.Province;
  }
  for (let municipality of geojsonFile.objects.municipalities.geometries) {
    if (location_lower === municipality.properties.name_fr.toLowerCase()) return LocationScale.Municipality;
    if (location_lower === municipality.properties.name_nl.toLowerCase()) return LocationScale.Municipality;
  }
  return LocationScale.ROOT;
}

/**
 *
 * @param position
 * @param zoom
 * @returns the municipality or province name in the center of the viewport
 */
export function findCurrentMapLocation(position: LngLat, zoom: number): string | null {
  const centerPoint = point([position.lng, position.lat]);
  const geojson = zoom > 10 ? geojsonMunicipalities : geojsonProvinces;

  for (let entity of geojson) {
    if (booleanPointInPolygon(centerPoint, entity)) {
      return entity.properties.name_fr;
    }
  }

  return null;
}

/**
 *
 * @param municipality the name of the municipality
 * @returns the province of the given municipality
 * @throws if the municipality is not found
 */
export function findProvinceForMunicipality(municipality: string): string {
  for (let municipality_ of geojsonFile.objects.municipalities.geometries) {
    if (municipality_.properties.prov_fr && municipality_.properties.name_fr.toLowerCase() === municipality.toLowerCase()) {
      return municipality_.properties.prov_fr;
    }
    if (municipality_.properties.prov_nl && municipality_.properties.name_nl.toLowerCase() === municipality.toLowerCase()) {
      return municipality_.properties.prov_nl;
    }
  }
  throw new Error(`Cannot find province for municipality ${municipality}`);
}

export function getGeojson(location: string) {
  const locationScale = extractLocationScale(location);
  const features = {
    [LocationScale.ROOT]: geojsonWallonia,
    [LocationScale.Province]: geojsonProvinces,
    [LocationScale.Municipality]: geojsonMunicipalities,
  }[locationScale];
  const feature =
    locationScale === LocationScale.ROOT
      ? features
      : features.find((feature) => {
          return (
            feature.properties.name_fr?.toLowerCase() === location?.toLowerCase() ||
            feature.properties.name_nl?.toLowerCase() === location?.toLowerCase()
          );
        });
  return feature;
}

export function findLocationGeojsonFeature(location: string) {
  const locationScale = extractLocationScale(location);
  const window_width = window.innerWidth;
  const features = {
    [LocationScale.ROOT]: geojsonWallonia,
    [LocationScale.Province]: geojsonProvinces,
    [LocationScale.Municipality]: geojsonMunicipalities,
  }[locationScale];
  const feature =
    locationScale === LocationScale.ROOT
      ? features
      : features.find((feature) => {
          return (
            feature.properties.name_fr?.toLowerCase() === location?.toLowerCase() ||
            feature.properties.name_nl?.toLowerCase() === location?.toLowerCase()
          );
        });
  return (feature || null) as Feature | null;
}

export function flyToLocation(map: Map, location: string) {
  const feature = findLocationGeojsonFeature(location);
  if (!feature) return;
  const bbox_ = bbox(feature);
  if (bbox_.some((d) => Number.isNaN(d) || !Number.isFinite(d)) || bbox_.length !== 4) return;
  //There is a bug with the fitBounds method at the start up of the map FIX IT
  //console.log("flyToLocation", bbox_);
  map.fitBounds([
    [bbox_[0], bbox_[1]],
    [bbox_[2], bbox_[3]],
  ]);
  return feature;
}

export function flyToCoordinate(map: Map, coordinate, zoom = 13) {
  map.flyTo({ center: coordinate, zoom });
}

/**
 * Empty component synchronizing the map with the location in the URL.
 */
export function SyncMapLocation() {
  const [location, setLocation] = useLocation();
  const map = useMap();
  const size = useSize();
  const [drawings] = useQueryParam<FeatureCollection | undefined>("drawings", JsonParam);

  useEffect(() => {
    // changing location when navigating
    if (!map.current) return;
    const map_ = map.current;
    const move = (viewState) => {
      const projection = map_.project({
        lon: viewState.longitude,
        lat: viewState.latitude,
      });
      const unprojection = map_.unproject(projection);
      setLocation(findCurrentMapLocation(unprojection, viewState.zoom));
    };
    function handleEvent(target) {
      if (!target.originalEvent) return;
      move(target.viewState);
    }
    const shouldUpdateLocation = () => (console.log("shouldUpdateLocation"), move(map_.getMap().getCenter()));

    map_.on("shouldUpdateLocation", shouldUpdateLocation);
    map_.on("dragend", handleEvent);
    map_.on("zoomend", handleEvent);

    return () => {
      map_.off("shouldUpdateLocation", shouldUpdateLocation);
      map_.off("dragend", handleEvent);
      map_.off("zoomend", handleEvent);
    };
  }, [map, setLocation]);

  useEffect(() => {
    // flying to location when url change
    if (!map.current) return;
    if (location) flyToLocation(map.current.getMap(), location);
    if (drawings) flyToGeojson(map.current.getMap(), drawings);
    //TODO: if search, fly to search

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
}

export const ROOT_TITLE = "Région Wallonne";
export const useLocation = () => {
  const [location, setLocationQuery] = useQueryParam("location", StringParam);

  const setLocation = (location: string | undefined | null, flyTo = null) => {
    setLocationQuery(location);
  };
  if (!location) setLocationQuery(ROOT_TITLE);
  setLocation.back = () =>
    setLocation(location && extractLocationScale(location) === LocationScale.Municipality ? findProvinceForMunicipality(location) : null);
  return [location, setLocation] as const;
};

export const ExtractLocationLevel = (location: string | undefined | null) => {
  if (!location) return null;
  const locationScale = extractLocationScale(location);
  let items: [string, string?, string?] = [ROOT_TITLE];
  switch (locationScale) {
    case LocationScale.Province:
      items.push(location);
      break;
    case LocationScale.Municipality:
      const province = findProvinceForMunicipality(location);
      items.push(province);
      items.push(location);
      break;
  }
  return items;
};

export const LocationBreadcrumb = () => {
  const [location, setLocation] = useLocation();
  const mapRef = useMap();
  const map = mapRef.current?.getMap()!;

  if (!location) return null;
  const locationScale = extractLocationScale(location);
  let breadcrumbItems: BreadcrumbItemType[] = [
    {
      title: ROOT_TITLE,
      onClick: () => {
        setLocation(null);
        flyToLocation(map, ROOT_TITLE);
      },
    },
  ];
  switch (locationScale) {
    case LocationScale.Province:
      breadcrumbItems.push({
        title: location,
      });
      break;
    case LocationScale.Municipality:
      const province = findProvinceForMunicipality(location);
      breadcrumbItems.push({
        title: province,
        onClick: () => {
          setLocation(province);
          flyToLocation(map, province);
        },
      });
      breadcrumbItems.push({ title: location });
      break;
  }
  return (
    <Row align={"middle"}>
      <Breadcrumb style={{ fontSize: 11 }} items={breadcrumbItems} />
    </Row>
  );
};

export function flyToGeojson(map: Map, geojson: object) {
  if (!geojson) return;
  const bbox_ = bbox(geojson);
  if (bbox_.some((d) => Number.isNaN(d) || !Number.isFinite(d)) || bbox_.length !== 4) return;
  map.fitBounds([
    [bbox_[0], bbox_[1]],
    [bbox_[2], bbox_[3]],
  ]);
}

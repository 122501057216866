import { useEffect, useRef, useState } from "react";
import usePromise from "react-use-promise";
import * as Plot from "@observablehq/plot";
import _ from "lodash";
import { Radio, Select } from "antd";
import { useResizeDetector } from "react-resize-detector";
import climatoData from "../../assets/data/climatoData.json";
import { geojsonWallonia, geojsonProvinces, geojsonMunicipalities } from "../../services/geojson";
import { getSearchCoordinates } from "../../components/Thematic/Utils/SearchBox";
import numeral from "numeral";

const ClimatoGridMap = ({ location }) => {
  const ref = useRef();
  const { width: componentWidth, ref: refParent } = useResizeDetector();
  const [chart, setChart] = useState(null);
  const [scenario, setScenario] = useState(climatoData.scenario[0].info);
  const [possibleValues, setPossibleValues] = useState({ degree: [], temporality: [] });
  const [pinMarker] = usePromise(() => getSearchCoordinates(location), [location]);

  useEffect(() => {
    const possibleDegree = _(climatoData.scenario)
      .filter((d) => d.info.variable === scenario.variable)
      .map((d) => d.info.degree)
      .uniq()
      .value();
    const degree = possibleDegree.includes(scenario.degree) ? scenario.degree : possibleDegree[0];
    const possibleTemporality = _(climatoData.scenario)
      .filter((d) => d.info.variable === scenario.variable && degree === scenario.degree)
      .map((d) => d.info.temporality)
      .uniq()
      .value();
    const temporality = possibleTemporality.includes(scenario.temporality) ? scenario.temporality : possibleTemporality[0];
    setPossibleValues({
      degree: possibleDegree,
      temporality: possibleTemporality,
    });
    if (scenario.degree !== degree || scenario.temporality !== temporality) {
      setScenario({
        ...scenario,
        degree,
        temporality,
      });
    }
  }, [scenario]);

  useEffect(() => {
    const data = climatoData.scenario.find(
      (d) => d.info.variable === scenario.variable && d.info.degree === scenario.degree && d.info.temporality === scenario.temporality,
    );
    if (componentWidth && data) {
      const grid = climatoData.grid
        .map((d, i) => ({
          longitude: d.split("_")[0],
          latitude: d.split("_")[1],
          value: data.values[i],
        }))
        .filter((d) => d.value !== 0);
      const chart = Plot.plot({
        width: componentWidth,
        height: componentWidth * 0.6,
        projection: {
          type: "mercator",
          domain: geojsonWallonia,
        },
        color: {
          legend: true,
          scheme: "viridis",
          label: scenario.variable,
          width: componentWidth,
          tickFormat: (d) => numeral(d).format("+0,0.[00]"),
        },
        marks: [
          Plot.geo(geojsonProvinces, { strokeWidth: 1.5 }),
          Plot.geo(geojsonMunicipalities, { strokeOpacity: 0.2 }),
          Plot.dot(grid, {
            fill: "value",
            stroke: "white",
            strokeWidth: 0.5,
            strokeOpacity: 0.5,
            x: "longitude",
            y: "latitude",
            r: componentWidth * 0.008,
            tip: true,
          }),
          pinMarker &&
            Plot.dot([pinMarker], {
              fill: "red",
              stroke: "white",
              strokeWidth: 1,
              strokeOpacity: 0.8,
              x: "longitude",
              y: "latitude",
              r: componentWidth * 0.005,
              tip: true,
              title: (d) => location,
            }),
        ],
      });

      ref.current.append(chart);
      setChart(chart);

      return () => chart.remove();
    }
  }, [climatoData, geojsonProvinces, geojsonMunicipalities, componentWidth, scenario, pinMarker]);

  return (
    <div ref={refParent}>
      <h5>Indicateurs</h5>
      <Select
        value={scenario.variable}
        style={{
          width: "100%",
          marginBottom: 8,
        }}
        onChange={(value) => setScenario({ ...scenario, variable: value })}
        options={_(climatoData.scenario)
          .map((d) => d.info.variable)
          .uniq()
          .orderBy()
          .value()
          .map((d) => ({ label: d, value: d }))}
      />
      <h5>Scénario</h5>
      <Radio.Group
        style={{ width: "100%", marginBottom: 8 }}
        block
        options={_(climatoData.scenario)
          .map((d) => d.info.degree)
          .uniq()
          .orderBy()
          .value()
          .map((d) => ({ label: `${d}°`, value: d, disabled: !possibleValues.degree.includes(d) }))}
        value={scenario.degree}
        onChange={(e) => setScenario({ ...scenario, degree: e.target.value })}
        buttonStyle="solid"
        optionType="button"
      />
      <h5>Temporalité</h5>
      <Radio.Group
        style={{ width: "100%", marginBottom: 8 }}
        block
        options={[{ label: "Annuel", value: "year", disabled: !possibleValues.temporality.includes("year") }]}
        value={scenario.temporality}
        onChange={(e) => setScenario({ ...scenario, temporality: e.target.value })}
        buttonStyle="solid"
        optionType="button"
      />
      <Radio.Group
        style={{ width: "100%", marginBottom: 8 }}
        block
        options={[
          { label: "Hiver", value: "hiver", disabled: !possibleValues.temporality.includes("hiver") },
          { label: "Automne", value: "automne", disabled: !possibleValues.temporality.includes("automne") },
          { label: "Printemps", value: "printemps", disabled: !possibleValues.temporality.includes("printemps") },
          { label: "Eté", value: "ete", disabled: !possibleValues.temporality.includes("ete") },
        ]}
        value={scenario.temporality}
        onChange={(e) => setScenario({ ...scenario, temporality: e.target.value })}
        buttonStyle="solid"
        optionType="button"
      />
      <Radio.Group
        style={{ width: "100%", marginBottom: 8 }}
        block
        options={[
          { label: "J", value: "janvier", disabled: !possibleValues.temporality.includes("janvier") },
          { label: "F", value: "fevrier", disabled: !possibleValues.temporality.includes("fevrier") },
          { label: "M", value: "mars", disabled: !possibleValues.temporality.includes("mars") },
          { label: "A", value: "avril", disabled: !possibleValues.temporality.includes("avril") },
          { label: "M", value: "mai", disabled: !possibleValues.temporality.includes("mai") },
          { label: "J", value: "juin", disabled: !possibleValues.temporality.includes("juin") },
          { label: "J", value: "juillet", disabled: !possibleValues.temporality.includes("juillet") },
          { label: "A", value: "aout", disabled: !possibleValues.temporality.includes("aout") },
          { label: "S", value: "septembre", disabled: !possibleValues.temporality.includes("septembre") },
          { label: "O", value: "octobre", disabled: !possibleValues.temporality.includes("octobre") },
          { label: "N", value: "novembre", disabled: !possibleValues.temporality.includes("novembre") },
          { label: "D", value: "decembre", disabled: !possibleValues.temporality.includes("decembre") },
        ]}
        value={scenario.temporality}
        onChange={(e) => setScenario({ ...scenario, temporality: e.target.value })}
        buttonStyle="solid"
        optionType="button"
      />
      <div ref={ref}></div>
    </div>
  );
};

export default ClimatoGridMap;
